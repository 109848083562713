@import '../imports';

html {
	font-family: $rlw;
	font-size: vh(1, 1080);
	@media #{$mobile} {
		height: 100%;
		font-size: vm(1, 375);
	}
}

#root, body, html {
	height: 100%;
	width: 100%;
}

body {
	background-color: $black;
	@media #{$mobile} {
		overflow: hidden;
	}
	&.hide-calendly-badge-widget {
		.calendly-badge-widget {
			display: none !important;
		}
	}
	&.page-post {
		background-color: #F2F2F2;
		.footer,
		.header {
			background: $black;
		}
		@media #{$mobile} {
			background-color: $white;
		}
	}
}

main {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	@media #{$mobile} {
		min-height: inherit;
		height: 100%;
	}
	&__content {
		flex-grow: 1;
	}
}

#__next {
	height: 100%;
	& > div{
		height: 100%;
	}
}

.container {
	width: calc(100% - 80rem);
	max-width: 1840rem;
	margin: 0 auto;
	@media #{$mobile} {
		width: calc(100% - 32rem);
	}
}

.common {
	&__h1-heading,
	&__h2-heading,
	&__h3-heading {
		font-family: $druk;
		font-weight: 700;
		color: $white;
	}
	&__h1-heading {
		font-family: $rlw;
		font-size: 54rem;
		line-height: 58rem;
		font-weight: 800;
		color: $yellow;
		@media #{$mobile} {
			font-size: 24rem;
			line-height: 30rem;
		}
	}
	&__h2-heading {
		font-size: 30rem;
		line-height: 42rem;
		@media #{$mobile} {
			font-size: 18rem;
			line-height: 24rem;
		}
	}
	&__h3-heading {
		font-size: 22rem;
		line-height: 32rem;
		@media #{$mobile} {
			font-size: 15rem;
			line-height: 21rem;
		}
	}
	&__heading-part {
		&_yellow {
			color: $yellow;
		}
		&_blue {
			color: $blue;
		}
		&_green {
			color: $green;
		}
		&_cyan {
			color: $cyan;
		}
		&_fuchsia {
			color: $fuchsia;
		}
		&_coral {
			color: $coral;
		}
	}
	&__button,
	&__button-link {
		transform: scale(1);
		padding: 17rem 56rem;
		font-size: 27rem;
		line-height: 32rem;
		font-weight: 800;
		color: $white;
		border-radius: 33rem;
		background-color: $black;
		transition: transform 0.5s 0s ease;
		@media #{$mobile} {
			padding: 8rem 34rem;
			font-size: 16rem;
			line-height: 24rem;
			border-radius: 20rem;
		}
		&:hover {
			transform: scale(1.05);
		}
		&_white {
			color: $black;
			background-color: $white;
		}
		&_grey {
			background-color: rgba(255, 255, 255, 0.10);;
		}
		&_brand {
			color: $black;
			background-color: $yellow;
		}
		&_small {
			padding: 14rem 23rem;
			font-size: 16rem;
			line-height: 20rem;
			font-weight: 600;
			border-radius: 24rem;
			@media #{$mobile} {
				padding: 11rem 18rem;
				font-size: 12rem;
				line-height: 16rem;
				border-radius: 19rem;
			}
		}
		&[disabled] {
			opacity: 0.5;
			pointer-events: none;
		}
	}
}
