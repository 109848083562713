@import '../../imports';

.index-intro {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	min-height: 100vh;
	@media #{$mobile} {
		align-items: flex-start;
		flex-direction: column;
		justify-content: flex-start;
		height: 100%;
		min-height: unset;
	}
	&__info {
		position: relative;
		top: -5rem;
		transform: translateY(0);
		width: 55%;
		flex-shrink: 0;
		opacity: 1;
		transition: transform 1s 0.2s ease, opacity 1s 0.2s ease;
		@media #{$mobile} {
			display: none;
		}
	}
	&__heading {
		font-size: 68rem;
		line-height: 92rem;
		max-width: 800rem;
		@media #{$mobile} {
			width: 70%;
			margin: 0 auto;
			font-family: $rlw;
			font-size: 18rem;
			line-height: 24rem;
		}
	}
	&__description {
		margin: 61rem 0 0 0;
		font-family: $mnsrt;
		font-size: 32rem;
		line-height: 43rem;
		font-weight: 500;
		color: $white;
		max-width: 559rem;
		@media #{$mobile} {
			max-width: 300rem;
			margin: 7rem auto 0 auto;
			font-size: 12rem;
			line-height: 17rem;
			br {
				display: none;
			}
		}& span:not([class]) {
			color: $yellow;
		}
	}

	&__main-picture {
		position: relative;
		height: 856px;
		width: 430px;
		overflow: hidden;
		display: flex;
		flex-shrink: 0;
		&::before {
			content: '';
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 33px;
			width: 130px;
			height: 37px;
			background-color: #262a2e;
			border-radius: 30px;
			z-index: 2;
		}
		@media #{$mobile} {
			width: 100%;
			height: 100%;
			padding: 0;
			justify-content: center;
			align-items: flex-start;
			margin-top: 0;
			margin-left: 0;
			&:before {
				content: none;
			}
		}
	}
	
	&__main-picture-img {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		@media #{$mobile} {
			display: none;
		}
	}

	&__betting-app {
		height: 812px;
		width: 375px;
		position: relative;
		overflow: hidden;
		margin: auto;
		border-radius: 54rem;
		@media #{$mobile} {
			width: 100%;
			height: 100%;
			border-radius: 0;
			margin: 0;
		}
	}
}
