.betting-list {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	
	&__header-img {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	&__widget-img {
		max-height: 206px;
	}

	&__app-img {
		width: 100%;
	}

	&__lion-img {
		position: absolute;
		left: -20%;
		top: 0;
		height: 355px;

	}

	&__header-btns {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		z-index: 99;
		padding: 0 25px;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
		top: 50px;
	}

	&__back-btn {
		width: 10px;
		height: 20px;
		display: flex;
		align-items: center;
		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__cart-btn {
		width: 25px;
		height: 25px;
		display: flex;
		align-items: center;
		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__header {
		flex-shrink: 0;
		position: relative;
		height: 292px;
		background: radial-gradient(100% 100% at 50.13% 100%, rgba(0, 0, 0, 0.00) 0%, rgba(109, 109, 109, 0.20) 100%), #474A57;
		background-color: #474A57;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		padding-bottom: 40px;
	}

	&__content {
		height: 100%;
		background: #2B2E3A;
		position: relative;
	}

	&__content-chat,
	&__content-app {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		bottom: 0;
		&--visible {
			opacity: 1;
			visibility: visible;
		}
	}

	&__content-chat {
		padding: 0;
		padding-bottom: 30px;
		background: #41434e;
		@media #{$mobile} {
			padding-bottom: 0;
		}
	}

	&__content-app { 
		padding: 24px;
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
	}
	
	&__toggle {
		position: absolute;
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		display: flex;
		width: 280px;
		height: 37px;
		border-radius: 4px;
		background: #24252C;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			border-radius: 4px;
			border: 1px solid #FF4B00;
			background: rgba(255, 75, 0, 0.30);
			box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.20);
			opacity: 0;
			transition: 0.4s opacity;
		}
		&--active:before {
			opacity: 1;
		}
	}

	&__checkbox-input {
		display: none;
		&:checked + label:after {
			left: 52%;
		}
		&:checked + label .betting-list__checkbox-label-content span:last-child{
			color: $black;
		}
		&:checked + label .betting-list__checkbox-label-content span:first-child{
			color: $white;
		}
	}

	&__checkbox-label {
		font-family: 'SFProText';
		color: #fff;
		font-size: 12px;
		font-weight: 500;
		text-align: center;
		border-radius: 4px;
		width: 100%;
		height: 100%;
		cursor: pointer;
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 4px;
			z-index: -1;
			left: 0;
		}
		&::after {
			content: '';
			position: absolute;
			width: 130px;
			height: 80%;
			border-radius: 4px;
			z-index: -1;
			left: 2%;
			top: 50%;
			transform: translateY(-50%);
			background: $white;
			transition: 0.4s left;
		}
	}

	&__checkbox-label-content {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		span {
			display: block;
			width: 50%;
			text-align: center;
			color: $black;
			transition: 0.4s color;
			&:last-child {
				color: $white;
			}
		}
	}

	&__toast {
		width: 90%;
		position: absolute;
		z-index: -1;
		left: 50%;
		transform: translateX(-50%);
		bottom: 100px;
		border-radius: 16px;
		background: rgba(0, 0, 0, 0.72);
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
		backdrop-filter: blur(16px);
		opacity: 0;
		transition: 0.6s opacity;
		&--visible {
			opacity: 1;
			z-index: 22;
		}
		@media #{$mobile} {
			bottom: 70px;
		}
	}

	&__toast-ico {
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}

	&__toast-info {
		display: flex;
		align-items: center;
		padding: 8px 12px;
		font-size: 13px;
		line-height: 16px;
		color: $white;
		font-family: 'SFProText';
		border-bottom: 1px solid rgba(255, 255, 255, 0.20);
		b {
			margin-right: 5px;
		}
		p {
			margin-left: 2px;
		}
	}
	&__toast-result {
		height: 40px;
		display: flex;
		align-items: center;
		padding: 8px 12px;
		font-size: 13px;
		line-height: 16px;
		color: $white;
		font-family: 'SFProText';
	}

	&__toast-result-total {
		margin-left: 16px;
	}
	
}
