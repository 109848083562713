@import '../../imports';

.modal {
	$block: '.modal';

	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 300px;
	border-radius: 16rem 16rem 0 0;
	background: #41434E;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);

	&--cart {
		.bet__event-outcome-title,
		.bet__event-title,
		.bet__event-outcome-factor {
			color: $white;
		}

		.bet__event-title {
			color: rgba(255, 255, 255, 0.60);
		}

		.bet__events {
			padding-top: 5px;
		}
	}
	
	&__calc {
		height: 48px;
		width: 100%;
		background-color: $black;
		display: flex;
		justify-content: space-between;
		border-radius: 8px;
		margin-bottom: 5px;
	}

	&__calc-field {
		width: calc(100% - 96px);
		position: relative;
		flex-shrink: 0;
		&:before {
			content: '$';
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 16px;
			color: $white;
		}
	}

	&__calc-input {
		font-family: 'SFProText';
		padding: 12px 16px;
		width: 100%;
		height: 100%;
		color: $white;
		font-size: 16px;
	}

	&__calc-btns {
		display: flex;
		width: 100%;
		max-width: 96px;
	}

	&__calc-btn {
		font-family: 'SFProText';
		color: $white;
		font-size: 20px;
		width: 50%;
		border-left: 1px solid var(--input-default-border, rgba(255, 255, 255, 0.20));
	}

	&__calc-value {
		font-family: 'SFProText';
		margin-bottom: 10px;
		font-size: 14px;
		line-height: 24px;
		font-weight: 400;
		color: rgba(255, 255, 255, 0.40);
		span {
			color: $white;
			font-weight: 500;
			margin-left: 4px;
		}
	}

	&__head {
		padding: 14px 16px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.10);
	}

	&__body {
		padding: 16px;
	}

	&__title {
		font-weight: 500;
		font-size: 17px;
		line-height: 22px;
		letter-spacing: -0.43px;
		color: $white;
		font-family: 'SFProText';

		@include mobile-medium-min {
			font-weight: 500;
			font-size: 20px;
			line-height: 25px;
			letter-spacing: 0.38px;
		}
	}

	&__subtitle {
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		color: var(--modal-section-title);

		@include mobile-medium-min {
			font-size: 16px;
			line-height: 24px;
			font-weight: 500;
		}
	}

	&__close{
		display: block;
		position: absolute;
		top: 14px;
		right: 16px;
		width: 24px;
		height: 24px;
		font-size: 0;
		color: transparent;
		background-repeat: no-repeat;
		background-position: center;
		user-select: none;
		z-index: 1;
	}

	&__body {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;
	}
}

.ReactModal__Overlay{
	position: absolute!important;
}
