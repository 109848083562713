@import '../../imports';

.btn {
	$block: '.btn';

	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 13px 20px;
	font-weight: 500;
	font-size: 17px;
	line-height: 22px;
	color: $white;
	background: #FF4B00;
	border: none;
	border-radius: 8px;
	transition: color 0.4s, background 0.4s, 0.4s opacity;
	user-select: none;
	outline: none;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.20);

	&:hover {
		background-color: rgba(#FF4B00, 0.8)
	}

	&:active {
		background-color: rgba(#FF4B00, 0.8)
	}

	&:disabled {
		opacity: 0.4;
	}
}
