@import '../../imports';

.bet {
	$block: '.bet';

	&--win {
		&__heading {
			background-color: var(--chat-bet-win);
		}

		&__status,
		&__title,
		&__date {
			color: var(--chat-bet-text-win);
		}
	}

	&--lose {
		&__heading {
			background-color: var(--chat-bet-lose)
		}
	}

	&--null {
		&__heading {
			background-color: var(--chat-bet-null)
		}
	}

	&__content {
		background: var(--chat-bet-background);
		font-family: 'SFProText';
	}

	&__heading {
		display: flex;
		justify-content: space-between;
		padding: 8px;
		border-radius: 8px 8px 0px 0px;
		width: calc(100% + var(--chat-message-padding) * 2);
		margin-left: calc(var(--chat-message-padding) * (-1));
	}

	&__heading-right {
		text-align: right;
	}

	&__title {
		font-size: 12px;
		line-height: 16px;
		font-weight: 500;
		color: var(--white);
	}

	&__total-factor {
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		color: var(--white);

		b {
			font-weight: 600;
			color: var(--white);
		}
	}

	&__date {
		margin-bottom: 6px;
		font-size: 12px;
		line-height: 16px;
		color: var(--white);
	}

	&__events {
		position: relative;
		margin-bottom: 8px;
		border-bottom: 1px solid var(--chat-bet-divider);

		&:last-child {
			margin-bottom: 0;
			border-bottom: none;
		}
	}

	&__events-btn {
		width: 100%;
		height: 32px;
		color: var(--branding);
		font-size: 12px;
		line-height: 30px;
		font-weight: 500;
		background-color: transparent;
		border: 1px solid var(--branding);
		border-radius: 4px;
		margin-top: 8px;
	}

	&__event {
		padding: 8px 0;
		border-bottom: 1px solid var(--chat-bet-divider);

		&:last-child {
			border-bottom: none;
		}
	}

	&__event-title {
		font-size: 12px;
		line-height: 16px;
		font-weight: 400;
		margin-bottom: 0;
		color: var(--text-secondary);
	}

	&__event-outcome {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__event-outcome-title {
		font-size: 13px;
		line-height: 16px;
		font-weight: 500;
		margin-bottom: 0;
	}

	&__event-outcome-factor {
		font-size: 13px;
		line-height: 16px;
		font-weight: 600;
		color: var(--text-primary);
		margin-left: 20px;
	}

	&__status {
		margin-bottom: 6px;
		font-size: 14px;
		line-height: 16px;
		font-weight: 600;
		color: var(--white);
	}

	&__bottom {
		.btn {
			width: 100%;
		}
	}
	
	&__time-repeated {
		font-size: 12px;
		line-height: 23px;
		font-weight: 40;
		color:rgba(122, 122, 122, 1);
		margin-top: 3px;
	}

	&__heading {
		background: rgba(255, 255, 255, 0.20);
		padding: 8px 12px;
		display: flex;
	}

	&__heading-info {
		display: flex;
		margin-bottom: 6px;
	}

	&__heading-right {
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__total-factor {
		font-size: 14px;
	}

	&__title {
		font-size: 13px;
		line-height: 16px;
		font-weight: 600;
		text-transform: capitalize;
		position: relative;
		padding-right: 12px;
		color: var(--chat-opponent-text);
		&:before {
			content: '';
			position: absolute;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #F88543;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__date {
		margin-bottom: 0;
		font-size: 11px;
		line-height: 14px;
		font-weight: 400;
		color: var(--chat-opponent-date)
	}

	&__status {
		font-size: 13px;
		font-weight: 500;
		margin-bottom: 0;
		margin-left: 6px;
		color:  var(--chat-opponent-date)
	}

	&__time-repeated {
		color:  var(--chat-opponent-date)
	}

	&__event-outcome-title {
		font-size: 14px;
		margin-bottom: 8px;
	}

	&__event-info {
		display: flex;
		justify-content: space-between;
	}

	&__event-line {
		width: 100%;
	}

	&__events {
		border-bottom: 0;
		padding-top: 12px;
	}

	&__event-status {
		display: inline-block;
		font-size: 12px;
		padding-left: 6px;
		line-height: 14px;
		font-weight: 400;
		color: var(--chat-opponent-date);
		text-transform: capitalize;
		border-radius: 2px;
	}

	&__event-status-ico {
		width: 8px;
		height: 8px;
		display: inline-block;
	}

	&__events-btn {
		border: 0;
		color: rgba(255, 255, 255, 1);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__events-btn-ico {
		width: 12px;
		height: 12px;
		display: block;
		margin-left: 12px;
		transform: rotate(90deg);
		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__bottom .btn {
		text-transform: uppercase;
	}

	&__event {
		border-bottom: 0;
		padding: 0;
		display: flex;
		padding-right: calc(12px - var(--chat-message-padding));
		position: relative;
		margin-bottom: 12px;
		padding-left: 12px;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 1px;
			height: 100%;
			border-radius: 20px;
			background-color: #525866;
		}
		
		&--lost {
			&:before {
				background-color: #FC0C4D;
			}
			&__event-status-ico {
				circle {
					stroke: #FC0C4D;
				}
			}
		}

		&--lost-return {
			&__event-status-ico {
				path:first-child {
					fill: #FC0C4D;
				}
			}
		}

		&--won,
		&--canceled,
		&--return {
			&:before {
				background-color: #5AAE73;
			}
			&__event-status-ico {
				circle {
					stroke: #5AAE73;
				}
			}
		}

		&--won-return {
			&__event-status-ico {
				path:first-child {
					fill: #5AAE73;
				}
			}
		}

		&--pending {
			&__event-status {
				font-size: 12px;
				padding-left: 6px;
			}
			&:before {
				background-color: #F88543;
			}
		}

	}

	&__event-outcome-factor {
		font-size: 14px;
	}

	&__event-title {
		color: var(--chat-opponent-date);
		font-size: 14px;
		margin-bottom: 8px;
	}

	&--lose {
		&__title:before {
			background-color: #FF2D55;
		}
	}

	&--win {
		&__title:before {
				background-color:#1B7E49;
			}
	}
}
