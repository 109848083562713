$rlw: 'Raleway';
$mnsrt: 'Montserrat';
$druk: 'DrukTextWide';
$prompt: 'Prompt';
$spartan: 'Spartan';

$black: #000;
$almost-black: #141414;
$dark: #161A1F;
$gray: #A9A9A9;
$white: #fff;
$yellow: #FFED00;
$blue: #5759FF;
$green: #00B15C;
$cyan: #00FFFF;
$fuchsia: #EF5DA8;
$coral: #FF2D55;
$red: #CB0303;

$sMobile: '(max-height:579px)';
$mobile: '(max-width:1024px)';
$desktop: '(min-width:1025px)';
$tovw: '(max-aspect-ratio: 4/3) and (min-width: 661px)';

$ios: '(-webkit-overflow-scrolling: touch)';

$ie: 'screen and (-ms-high-contrast: active), (-ms-high-contrast: none)';
