.cookie-popup {
	position: fixed;
	z-index: 999;
	width: 280rem;
	bottom: 16rem;
	right: 16rem;
	border-radius: 12rem;
	background: rgba(39, 39, 39, 0.80);
	box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.30);
	backdrop-filter: blur(12rem);
	padding: 16rem 16rem 24rem 16rem;
	opacity: 0;
	visibility: hidden;
	transition: 0.4s opacity, 0.4s visibility;
	@media #{$mobile} {
		width: 90%;
		left: 50%;
		transform: translateX(-50%);
	}
	&--visible {
		opacity: 1;
		visibility: visible;
	}

	&__text {
		font-family: 'Montserrat';
		font-size: 12rem;
		line-height: 16rem;
		font-weight: 400;
		color: $white;
		margin-bottom: 16rem;
		@media #{$mobile} {
			font-size: 10rem;
			line-height: 14rem;
		}
		p {
			margin-bottom: 12rem;
		}
	}

	&__link {
		color: $yellow;
		margin-left: 4rem;
	}

	&__btn {
		padding: 8rem 16rem;
		font-weight: 700;
		font-size: 14rem;
		line-height: 16rem;
		&:first-child {
			margin-right: 8rem;
		}
		@media #{$mobile} {
			font-size: 12rem;
		}
	}
}
