.betting-start {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #2B2E3A;
	
	&__header-img {
		width: 100%;
		position: absolute;
	}

	&__img {
		width: 100%;
	}

	&__list {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__item {
		width: 100%;
		position: relative;

		img {
			width: 100%;
		}
	}

	&__btn {
		position: relative;
		z-index: 2;
		width: 100%;

		&:before {
			content: '';
			position: absolute;
			left: 12px;
			top: 8px;
			right: 12px;
			bottom: 16px;
			border-radius: 8px;
			border: 1px solid #FF4B00;
			background: rgba(255, 75, 0, 0.30);
			box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.20);
			opacity: 0;
			transition: 0.4s opacity;
		}
		&--active:before {
			opacity: 1;
		}
	}

}
