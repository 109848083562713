.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 22;
	width: 100%;
	background: linear-gradient($black 85%, rgba($black, 0));
	background: rgba(0, 0, 0, 0.40);
	backdrop-filter: blur(10px);
	@media #{$mobile} {
		display: none;
	}
	
	&__inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: vh(22) 0;
		@media #{$mobile} {
			padding: 27rem 0;
		}
	}
	&__burger {
		display: none;
		@media #{$mobile} {
			position: relative;
			top: 0;
			display: block;
			width: 30rem;
			height: 20rem;
		}
		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 2px;
			background-color: $white;
			transition: top 0.5s 0.5s ease, transform 0.5s 0s ease;
		}
		&::after {
			top: vh(18);
		}
		&-line {
			position: absolute;
			top: vh(9);
			left: 0;
			width: 100%;
			height: 2px;
			background-color: $white;
			opacity: 1;
			transition: opacity 0s 0.5s linear;
		}
	}
	&__logo {
		position: relative;
		left: vh(-2);
		display: flex;
		cursor: pointer;
		@media #{$mobile} {
			position: absolute;
			top: calc(50% - 2rem);
			left: calc(50% - 6rem);
			transform: translate(-50%, -50%);
		}
		&-sign {
			display: block;
			width: vh(44);
			height: vh(31);
			@media #{$mobile} {
				width: 36rem;
				height: 26rem;
			}
		}
		&-text {
			position: relative;
			top: vh(-1);
			width: vh(152);
			margin: 0 0 0 vh(14);
			@media #{$mobile} {
				display: none;
			}
		}
	}
	&__main-menu {
		position: absolute;
		top: calc(50% - #{vh(2)});
		left: 50%;
		transform: translate(-50%, -50%);
		@media #{$mobile} {
			position: fixed;
			top: 0;
			left: 0;
			transform: none;
			width: 0;
			z-index: 1;
			height: 100%;
			padding: 92rem 0;
			overflow: hidden auto;
			background-color: $yellow;
			transition: width 0.5s 0.5s ease;
		}
		&-close-button {
			display: none;
			@media #{$mobile} {
				display: block;
				position: absolute;
				top: 24rem;
				right: 20rem;
				transform: rotate(45deg);
				width: 30rem;
				height: 30rem;
				opacity: 0;
				transition: opacity 0.3s 0s ease;
			}
			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 14rem;
				left: 0;
				display: block;
				width: 100%;
				height: 2px;
				border-radius: 1px;
				background-color: $black;
			}
			&::after {
				transform: rotate(90deg);
			}
		}
		&-list {
			display: flex;
			@media #{$mobile} {
				flex-direction: column;
				align-items: center;
				margin: 0 0 -45rem 0;
				opacity: 0;
				pointer-events: none;
				transition: opacity 0.5s 0s ease;
			}
		}
		&-item {
			margin: 0 26rem;
			@media #{$mobile} {
				margin: 0 0 45rem 0;
			}
			&--demo {
				display: none;
				@media #{$mobile} {
					display: block;
				}
			}
			&-button {
				white-space: nowrap;
				position: relative;
				font-size: vh(16);
				line-height: vh(24);
				font-weight: 600;
				color: $white;
				opacity: 1;
				transition: 0.5s 0s ease;
				a {
					color: $white;
					@media #{$mobile} {
						color: $black;
					}
				}

				@media #{$tovw} {
					font-size: 24rem;
					line-height: 32rem;
				}

				@media #{$mobile} {
					font-size: 18rem;
					line-height: 24rem;
					color: $black;
				}
				&::after {
					content: '';
					position: absolute;
					bottom: vh(-4);
					left: 0;
					display: block;
					width: 0;
					height: 2px;
					background-color: $yellow;
					transition: width 0.3s 0s ease;
				}
				&:hover,
				&[disabled] {
					@media #{$mobile} {
						opacity: 0.5;
					}
					&::after {
						width: 100%;
					}
				}
			}
		}
	}
	&__right {
		display: flex;
		align-items: center;
		position: relative;
		top: vh(-2);
		right: vh(-7);
		@media #{$mobile} {
			top: 0;
			right: 0;
		}
	}
	&__show-demo-button,
	&__get-started-button {
		font-size: vh(16);
		line-height: vh(24);
		font-weight: 600;
		color: $yellow;
	}
	&__show-demo-button {
		display: block;
		margin-right: vh(30);
		a {
			color: $yellow;
		}
		@media #{$mobile} {
			display: none;
		}
	}
}

body.burger-open {
	& .header__burger {
		&-line {
			opacity: 0;
		}
		&::before,
		&::after {
			top: vh(9);
			transition: top 0.5s 0s ease, transform 0.5s 0.5s ease;
		}
		&::before {
			transform: rotate(45deg);
		}
		&::after {
			transform: rotate(-45deg);
		}
	}
	& .header__main-menu {
		@media #{$mobile} {
			width: 100%;
			transition: width 0.5s 0s ease;
		}
		&-close-button {
			@media #{$mobile} {
				opacity: 1;
				transition: opacity 0.3s 0.5s ease;
			}
		}
		&-list {
			@media #{$mobile} {
				opacity: 1;
				pointer-events: all;
				transition: opacity 0.5s 0.5s ease;
			}
		}
	}
}
