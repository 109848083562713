@font-face {
	font-family: 'Montserrat';
	src: url("../fonts/Montserrat-Regular.woff2") format('woff2'), url("../fonts/Montserrat-Regular.woff") format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url("../fonts/Montserrat-Medium.woff2") format('woff2'), url("../fonts/Montserrat-Medium.woff") format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url("../fonts/Montserrat-Bold.woff2") format('woff2'), url("../fonts/Montserrat-Bold.woff") format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url("../fonts/Montserrat-ExtraBold.woff2") format('woff2'), url("../fonts/Montserrat-ExtraBold.woff") format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url("../fonts/Raleway-Medium.woff2") format('woff2'), url("../fonts/Raleway-Medium.woff") format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url("../fonts/Raleway-SemiBold.woff2") format('woff2'), url("../fonts/Raleway-SemiBold.woff") format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url("../fonts/Raleway-Bold.woff2") format('woff2'), url("../fonts/Raleway-Bold.woff") format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url("../fonts/Raleway-ExtraBold.woff2") format('woff2'), url("../fonts/Raleway-ExtraBold.woff") format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'DrukTextWide';
	src: url("../fonts/DrukTextWide-Bold.woff2") format('woff2'), url("../fonts/DrukTextWide-Bold.woff") format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Prompt';
	src: url("../fonts/Prompt-Bold.woff2") format('woff2'), url("../fonts/Prompt-Bold.woff") format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Prompt';
	src: url("../fonts/Prompt-Regular.woff2") format('woff2'), url("../fonts/Prompt-Regular.woff") format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Spartan';
	src: url("../fonts/Spartan-SemiBold.woff2") format('woff2'), url("../fonts/Spartan-SemiBold.woff") format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SFProText';
	src: local('SFProText'), local('SFProText-Semibold'),
		url('../fonts/SFProText-Semibold.woff2') format('woff2'), url('../fonts/SFProText-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SFProText';
	src: local('SFProText'), local('SFProText-Medium'),
		url('../fonts/SFProText-Medium.woff2') format('woff2'), url('../fonts/SFProText-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SFProText';
	src: local('SFProText'), local('SFProText-Light'),
		url('../fonts/SFProText-Light.woff2') format('woff2'), url('../fonts/SFProText-Light.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
